var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: this.$route.name, attrs: { id: "app" } }, [
    this.$route.name == "maintenance"
      ? _c("div", { staticClass: "maintenance" }, [_c("router-view")], 1)
      : _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "config-wrapper",
                attrs: { "v-bind": _vm.config },
              },
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      show: _vm.VUE_APP_MODE !== "production",
                      id: "debug-alert",
                    },
                  },
                  [
                    _c("b", [_vm._v("SITO DI PROVA")]),
                    _c("br"),
                    _vm._v("\n        vai su "),
                    _c("a", { attrs: { href: "https://www.salabam.com" } }, [
                      _vm._v("salabam.com"),
                    ]),
                  ]
                ),
                Object.keys(_vm.config).length !== 0
                  ? _c(
                      "div",
                      { staticClass: "config-wrapper" },
                      [
                        _vm.showHeader
                          ? _c("PageHeader", {
                              attrs: { "v-bind": _vm.showHeader },
                            })
                          : _vm._e(),
                        _c(
                          "main",
                          { attrs: { id: "appMain" } },
                          [_c("router-view")],
                          1
                        ),
                        _vm.showFooter
                          ? _c("PageFooter", {
                              attrs: { "v-bind": _vm.showFooter },
                            })
                          : _vm._e(),
                        this.mixinGetIntegrationInfo().sso == false &&
                        _vm.config.guiSettings &&
                        _vm.config.guiSettings.integrations &&
                        _vm.config.guiSettings.integrations.scalapay.enabled &&
                        _vm.config.guiSettings.integrations.scalapay.showPromo
                          ? _c("StickyBanner")
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "b-container",
                      { staticClass: "loading-app", attrs: { fluid: "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "logo" },
                          [_c("SvgLogoOrange")],
                          1
                        ),
                        _c("Spinner"),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c("ModalNotification", { ref: "notification" }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }