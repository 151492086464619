//import 'core-js/stable'
//import 'regenerator-runtime/runtime'
//import 'intersection-observer'
//import '@babel/polyfill'
import Vue from 'vue'
import Store from './vuex/store.js'
import VueLocalStorage from 'vue-localstorage'
import VueGtm from 'vue-gtm';
import Router from './router'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import it from 'date-fns/locale/it'
import format from 'date-fns/format'
import VueMeta from 'vue-meta'
import Utilities from './utilities'
import Config from './config'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
window.eventBus = new Vue() //catch events from api.js

Vue.component('font-awesome-icon', FontAwesomeIcon)

const monthNames = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre']
const days = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica']
const daysShort = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']

Vue.use(VueCookies)
Vue.$cookies.config('30d')
Vue.use(VueLocalStorage)
Vue.use(VueLazyload, {})
Vue.use(BootstrapVue, {breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']})
Vue.use(AirbnbStyleDatepicker, {
  dateLabelFormat: 'dddd, MMMM D, YYYY',
  days: days,
  daysShort: daysShort,
  monthNames: monthNames,
  colors: {
    selected: 'rgba(0,119,202,1)',
    inRange: 'rgba(0,119,202,0.35)',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: '#39B54A',
    disabled: '#ffffff',
    hoveredInRange: 'rgba(0,119,202,0.35)'
  },
  texts:
  {
    apply: 'Conferma',
    cancel: 'Annulla',
    keyboardShortcuts: 'Scorciatoie'
  }
})

Vue.mixin(Utilities);
Vue.mixin(Config);

Vue.filter('trim', function (value) {
  if (!value) return ''
  return value.trim()
})
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value = value.toString().toUpperCase()
})
Vue.filter('lowercase', function (value) {
  if (!value) return ''
  return value = value.toString().toLowerCase()
})
Vue.filter('formatDate', function (value) {
  if (!value) return ''
  return value = format(value, 'DD/MM/YYYY', {locale: it})
})
Vue.filter('monthName', function (value) {
    if (!value) return ''
    window.console.log(parseInt(value))
    return monthNames[(parseInt(value)-1)]
})
  

/*
new Vue({
  render: h => h(App),
}).$mount('#app')
*/

Store.subscribe((mutation, state) => {
  //Vue.localStorage.set('UePw4IHvDhJgnKIe', JSON.stringify(state))
  Vue.localStorage.set(process.env.VUE_APP_STORE_KEY, JSON.stringify(state))
})


Vue.use(VueGtm, {
  id: process.env.VUE_APP_TAG_MANAGER, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  /*queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
    gtm_preview:'env-4',
    gtm_cookies_win:'x'
  },*/
  enabled: (process.env.VUE_APP_TAG_MANAGER_ENABLED == 'true'), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: (process.env.VUE_APP_DEBUG == 'true'), // Whether or not display console logs debugs (optional)
  loadScript: (process.env.VUE_APP_TAG_MANAGER_ENABLED == 'true'), // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: Router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

new Vue({
  router: Router,
  el: '#app',
  store: Store,
  mixins: [
    Utilities,
    Config
  ],
  render: h => h(App),
  // Full VUE component lifecycle:
  beforeCreate () {
    //window.console.log('Vue: beforeCreate()');
    Vue.prototype.$config = {}
  },
  created () {
    //window.console.log('Vue: created()');
  },
  beforeMount () {
    //window.console.log('Vue: beforeMount()');
  },
  mounted () {
    //window.console.log('Vue: mounted()');
  },
  beforeUpdate() {
    //window.console.log('Vue: beforeUpdate()');
  },
  update () {
    //window.console.log('Vue: update()');
  },
  beforeDestroy () {
    //window.console.log('Vue: beforeDestroy()');
  },
  destroyed () {
    //window.console.log('Vue: destroyed()');
  }
})

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if(process.env.VUE_APP_SENTRY_DSN){

  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_MODE,
    //dsn: "https://20fb142ff5784ae88f8bb0d9fa506d63@o770004.ingest.sentry.io/5795292",
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        //tracingOrigins: ["www.salabam.g", "localhost:8089"],
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: (process.env.VUE_APP_MODE == 'production')? 0.2 : 1.0,

    debug: process.env.VUE_APP_MODE  ==  'development',

    tracingOptions: {
      trackComponents: true,
    },

    // Vue specific
    //trackComponents: ["Property", "GmapMap"],
    //hooks: ["create", "mount", "destroy"],
    logErrors: (process.env.VUE_APP_MODE == 'production')? false : true, // altrimenti non passa gli errori in dev console
    attachProps: true,
    attachStacktrace: true,

  });

}