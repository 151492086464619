<template>
  <div id="app" :class="this.$route.name">
    <div class="maintenance" v-if="this.$route.name == 'maintenance'">
      <router-view/>
    </div>
    <div v-else>
      <div :v-bind="config" class="config-wrapper">

        <b-alert :show="VUE_APP_MODE !== 'production'" id="debug-alert">
          <b>SITO DI PROVA</b>
          <br>
          vai su <a href="https://www.salabam.com">salabam.com</a>
        </b-alert>

        <div v-if="Object.keys(config).length !== 0"  class="config-wrapper">
          <PageHeader v-if="showHeader" :v-bind="showHeader" />
          <main id="appMain">
            <router-view/>
          </main>
          <PageFooter v-if="showFooter" :v-bind="showFooter" />
          <StickyBanner v-if="this.mixinGetIntegrationInfo().sso == false && config.guiSettings && config.guiSettings.integrations && config.guiSettings.integrations.scalapay.enabled && config.guiSettings.integrations.scalapay.showPromo" />
        </div>
        <b-container fluid v-else class="loading-app">
          <div class="logo"><SvgLogoOrange /></div>
          <Spinner />
        </b-container>
      </div>

      <!-- notification modal -->
      <ModalNotification ref="notification"/>
    </div>
  </div>

</template>

<script>
  import Store from './vuex/store'
  import StickyBanner from '@/components/blocks/sticky-banner'

  export default {
    name: 'app',
    metaInfo: {
      title: 'Un voucher per viaggiare',
      titleTemplate: '%s - ' + process.env.VUE_APP_SITE_TITLE,
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Prenota online i tuoi viaggi in modo semplice e soprattutto sicuro. Salabam, soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
        //{ vmid: 'metaKeywords', name: 'Keywords', content: 'salabam, viaggi online, online travel booking, online travel agency, agenzia viaggi online, prenotazione online viaggi, viaggi welfare aziendale, viaggi welfare, viaggi promozioni, viaggi marketing, viaggi viaggi programmi fedeltà, viaggi programma fedeltà, viaggi loyalty program, catalogo a premi viaggi' },
        { vmid: 'metaAbstract', name: 'abstract', content: 'Salabam è un servizio di prenotazione viaggi online pensato per gli utenti del welfare aziendale. Salabam offre soluzioni di online travel booking per i programmi fedeltà, per le promozioni e i concorsi' },
        { vmid: 'metaClassification', name: 'Classification', content: 'Commercial' },
        { vmid: 'metaResourceType', name: 'resource-type', content: 'document' },
        { vmid: 'metaDistribution', name: 'Distribution', content: 'Global' },
        { vmid: 'metaRating', name: 'Rating', content: 'General' },
        { vmid: 'metaLanguage', name: 'Language', content: 'IT' },
        { vmid: 'metaOwner', name: 'Owner', content: 'Salabam SRL' },
        { vmid: 'ogTitle', property: 'og:title', content: 'Prenota online in tempo reale hotel, beb, appartamenti e ville' },
        { vmid: 'ogSiteName', name: 'site_name', content: 'Salabam | viaggi online' },
        { vmid: 'ogType', name: 'og:type', content: 'website' },
        { vmid: 'ogUrl', name: 'og:url', content: window.location.href },
        { vmid: 'ogImage', name: 'og:image', content: process.env.VUE_APP_SITE_HOST + 'assets/salabam_logo_512.png' },
        { vmid: 'ogImageWidth', name: 'og:image:width', content: '512' },
        { vmid: 'ogImageHeight', name: 'og:image:height', content: '512' },
        { vmid: 'ogDescription', name: 'og:description', content: 'Prenota online i tuoi viaggi in modo semplice e soprattutto sicuro. Salabam, soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
        { vmid: 'dcTitle', name: 'DC.title', lang: 'it', content: 'Prenota online i tuoi viaggi in modo semplice e soprattutto sicuro. Salabam, soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
        { vmid: 'dcCreator', name: 'DC.creator', content: 'Salabam SRL' },
        { vmid: 'dcSubject', name: 'DC.subject', lang: 'it', content: 'salabam;viaggi online;online travel booking;online travel agency;agenzia viaggi online;prenotazione online viaggi;viaggi welfare aziendale;viaggi welfare;viaggi promozioni;viaggi marketing;viaggi viaggi programmi fedeltà;viaggi programma fedeltà;viaggi loyalty program;catalogo a premi viaggi' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Salabam è un servizio di prenotazione viaggi online pensato per gli utenti del welfare aziendale. Salabam offre soluzioni di online travel booking per i programmi fedeltà, per le promozioni e i concorsi' },
        { vmid: 'dcType', name: 'DC.type', scheme: 'DCTERMS.DCMIType', content: 'Text' },
        { vmid: 'dcFormat', name: 'DC.format', content: 'text/html' },
        { vmid: 'dcIdentifier', name: 'DC.identifier', scheme: 'DCTERMS.URI', content: process.env.VUE_APP_SITE_HOST },
        { vmid: 'dcLanguage', name: 'DC.language', scheme: 'DCTERMS.RFC1766', content: 'IT' },
        { vmid: 'robots', name: 'robots', content: process.env.VUE_APP_META_ROBOTS },
      ]
    },
    components: {
      StickyBanner,
      PageHeader: () => import('@/components/blocks/topbar/page-header').then(function(PageHeader) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async: PageHeader Component loaded')
        return PageHeader
      }),
      PageFooter: () => import('@/components/blocks/footer/page-footer').then(function(PageFooter) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async: PageFooter Component loaded')
        return PageFooter
      }),
      Spinner: () => import('@/components/atoms/spinner').then(function(Spinner) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Spinner Component loaded')
        return Spinner
      }),
      SvgLogoOrange: () => import('@/components/svg/logo-orange').then(function(SvgLogo) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SvgLogo Component loaded')
        return SvgLogo
      }),
      ModalNotification: () => import('@/components/blocks/modal-notification').then(function(ModalNotification) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ModalNotification Component loaded')
        return ModalNotification
      }),
    },
    data() {
      return {
        config: {},
        hideHeaderInRoutes: [
            //'search',
            //'property',
            //'map-list',
            //'map-detail',
            //'before-checkout',
            'ticket-compliments',
        ],
        showHeader: false,
        hideFooterInRoutes: [
            'search',
            'property',
            'map-list',
            'map-detail',
            'before-checkout',
            'ticket-compliments',
        ],
        showFooter: false,
        node_env: process.env.VUE_APP_MODE,
        VUE_APP_MODE: process.env.VUE_APP_MODE,
      }
    },
    beforeMount() {

      let _this = this

      this.appConfig()
        .then(value => {

          // spiano integration, c'è sempre qualcosa che si incarta (un setAvailability in giro che parte quando gli pare)
          Store.commit({
            type: 'setIntegration',
            value: {},
          })

          Store.commit({
            type: 'setNightlyPrice',
            value: 0,
          })

          Store.commit({
            type: 'setUnlockUpselling',
            value: false,
          })

          if(this.config.integration) delete this.config.integration

          this.config = value

          if(value.integration){

            this.$store.state.integration.availability = -1

            if(typeof value.integration.availability !== undefined){ // può essere 0
              this.mixinSendMutation('setAvailability',parseFloat(value.integration.availability))
            }

            setTimeout(function(){

              if(window.Tawk_API) {
                //window.Tawk_API.onLoad = function () {

                // non passare attributi vuoti a tawk che si incazza
                let tawkAttributes = {
                  'name': (value.integration.displayName)? value.integration.displayName : '-',
                  'email': (value.integration.email)? value.integration.email : '-',
                  'token': (value.token)? value.token : '-',
                  'portale': (value.integration.customer)? value.integration.customer : '-',
                  'azienda': (value.integration.company)? value.integration.company : '-',
                  'credito': (value.integration.availability)? value.integration.availability : '-',
                  'confirmcode': (value.integration.salabam && value.integration.salabam.confirmcode)? value.integration.salabam.confirmcode : '-',
                };

                if(process.env.VUE_APP_MODE != 'production') window.console.log('TAWK attributes')
                if(process.env.VUE_APP_MODE != 'production') window.console.log(tawkAttributes)

                if(window.Tawk_API && window.Tawk_API.setAttributes) window.Tawk_API.setAttributes(tawkAttributes, function (error) {

                  if(error) {
                    window.console.log('appConfig tawk setAttributes error')
                    window.console.log(error)
                  }

                })
                //}
              }

              // tracking sso
              // per il tracciamento uso
              // o l'email del salabam usato
              // l'email dell'integrazione
              // in extrema ration il token

              let logrocketUserTrackingId = (value.integration.salabam && value.integration.salabam.email)? value.integration.salabam.email :  (value.integration.email)? value.integration.email : value.token;

              if(window.LogRocket) {
                window.LogRocket.identify(logrocketUserTrackingId, {
                  name: (value.integration.displayName)? value.integration.displayName : '',
                  email: logrocketUserTrackingId,
                  // custom variables
                  customer: (value.integration.customer)? value.integration.customer : '',
                  company: (value.integration.company)? value.integration.company : '',

                  confirmcode: (value.integration.salabam && value.integration.salabam.confirmcode)? value.integration.salabam.confirmcode : '',

                  token: (value.token)? value.token : '',
                });

                if(!value.integration.salabam && window.LogRocket) window.LogRocket.track('sso')
                if(value.integration.salabam && window.LogRocket) window.LogRocket.track('loginSalabam')
              }

              let gtmAction = (value.integration.salabam)? 'loginSalabam' : 'sso';
              let gtmLabel = (value.integration.customerDisplayName)? value.integration.customerDisplayName : '';
              let gtmValue = (value.integration.salabam)? value.integration.salabam.public_price : (value.integration.availability >= 0)? value.integration.availability : 0;

              if(_this.$gtm){

                _this.$gtm.trackEvent({
                  event: 'customEvent',  // Event type [default = 'interaction'] (Optional) tag manager tracks 'customEvent'
                  category: 'userBehaviour',
                  action: gtmAction,
                  label: gtmLabel,
                  value: gtmValue,
                  //noninteraction: false // Optional
                })

                _this.$gtm.trackEvent({
                  event: 'customEvent',
                  category: 'partnerBehaviour',
                  action: gtmAction,
                  label: gtmLabel,
                  value: gtmValue,
                })


                if(gtmAction == 'sso'){

                  _this.$gtm.trackEvent({
                    event: 'customEvent',
                    category: 'partnerBehaviour',
                    action: 'welfare',
                    label: (value.integration.welfare)? 'welfare' : '',
                    value: (value.integration.welfare)? 1 : 0,
                  });

                  _this.$gtm.trackEvent({
                    event: 'customEvent',
                    category: 'companyBehaviour',
                    action: 'sso',
                    label: value.integration.company,
                    value: (value.integration.availability >= 0)? value.integration.availability : 0,
                  });

                } // if gtmAction == sso

              } // if this.$gtm


              if(window.clarity){
                window.clarity("set", "token", value.token)
                window.clarity("set", "customer", (value.integration.customer)? value.integration.customer : '')
                window.clarity("set", "company", (value.integration.company)? value.integration.company : '')
                window.clarity("set", gtmAction, (value.integration.email)? value.integration.email : '') // sso|salabamLogin
              }

              if(window.fbq && gtmAction == 'sso') window.fbq('trackCustom', 'Sso', {
                customer: (value.integration.customer)? value.integration.customer : '',
                company: (value.integration.company)? value.integration.company : '',
                availability: (value.integration.availability >= 0)? value.integration.availability : 0,
              });

            }, 2000); // setTimeout


          } // if value.integration

        })
    },
    watch: {
      '$route.name': function () {
        this.pageLayoutSetup()
      }
    },
    mounted()
    {
      let _this = this
      window.eventBus.$on('gotNotification', function(data) { 
        _this.$refs.notification.handleNotification(data)
      })
      this.pageLayoutSetup()
    },
    created () {
      this.handleScroll()
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      pageLayoutSetup()
      {
        this.showHeader = !this.hideHeaderInRoutes.includes(this.$route.name)
        this.showFooter = !this.hideFooterInRoutes.includes(this.$route.name)
      },
      handleScroll () {
        if(window.top.scrollY)
        {
          document.body.classList.add('scrolling')
        }
        else
        {
          document.body.classList.remove('scrolling')
        }
      }
    }
  }
</script>

<style lang="scss">
  @import 'scss/_bootstrap.scss';
  @import 'scss/_font-poppins.scss';
  @import 'scss/_font-yantramanov.scss';
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
  @import 'scss/_app.scss';
  .loading-app
  {
    margin-top:$grid-gutter-width/2;
    .logo
    {
      text-align:center;
    }
    svg
    {
      max-width: 90%;
      width:220px;
      margin:$grid-gutter-width/2 auto;
    }
  }
  #debug-alert
  {
    position: fixed;
    bottom: $grid-gutter-width/2;
    left: $grid-gutter-width/2;
    z-index: 999999;
    background: $danger;
    border-color:$danger;
    color:$white;
    text-align:center;
    padding:1rem 1.5rem;
    a,a:hover
    {
      color:$white;
      text-decoration: underline;
    }
    b
    {
      font-size: 20px;
    }
    @include media-breakpoint-down(md)
    {
      left:0;
      right:0;
      max-width:180px;
      bottom:20px;
      margin:0 auto;
      padding:10px 12px;
      font-size: 11px;
      b
      {
        font-size: 16px;
      }
    }
  }
</style>