import Router from './router'
import Store from './vuex/store'
import Api from './api'
import _ from 'lodash';

export default {
    /*
    created() {
        //window.console.log('Utilities Mixin Creted')
    },
    */
    methods: {
        mixinSortResults: function(data,key,order)
        {
            if(this.$router.history.current.name == 'map-list')
            {
                this.mixinSendMutation('setScroll',0)
            }
            let results = data.sort(function(a, b) {
                return a[key] - b[key];
            })
            if(order == 'DESC')
            {
                results = results.reverse()
            }
            return results
        },
        mixinLogout: function()
        {
            if(window.LogRocket) window.LogRocket.track('logout')
            return Api.logout()
        },
        mixinUseTicketCompliments: function()
        {
            // abilita o disabilita integrazione edenred ticket compliments
            // nascondendo ogni riferimento
            return this.$config.guiSettings.integrations.etc.enabled
        },
        mixinUsePromoshopping: function()
        {
          // mostra o nasconde i riferimenti a promoshopping
          // (lato "tech" sono buoni sconto normalissimi)
            return this.$config.guiSettings.integrations.promoshopping.enabled
        },
        mixinUseScalapay: function()
        {
            // abilita o disabilita integrazione scalapay
            return this.$config.guiSettings.integrations.scalapay.enabled
        },
        mixinUseSatispay: function()
        {
            // abilita o disabilita integrazione scalapay
            return this.$config.guiSettings.integrations.satispay.enabled
        },
        mixinIsAuthenticated: function()
        {
            let token = Store.state.token
            if(Router.history.current.query.token) token = Router.history.current.query.token
            if(!token) return false
            if(!Store.state.integration) return false
            return true
        },
        mixinSendMutation: function(type,value)
        {
            Store.commit({
                type: type,
                value: value,
            })
        },
        mixinGoBack()
        {
            if(process.env.VUE_APP_MODE != 'production') window.console.log('mixinGoBack')
            Router.go(-1)
        },
        mixinGoTo: function(routeName,routeParameters)
        {
            if(routeParameters.newWindow)
            {
                let store = window.btoa((JSON.stringify(Store.state))).replace(/[^0-9a-z]/gi, '')
                let routeData = Router.resolve({
                    name: routeName,
                    params: routeParameters,
                    query:{
                        state: store
                    }
                });
                window.open(routeData.href, '_blank')
            }
            else
            {
                Router.push({
                        name: routeName,
                        params: routeParameters
                    }).catch(() => {
                })
            }
        },
        mixinRoundTwoDigits: function(value)
        {
            if(value <= 0) return value // availability può essere -1
            return ((Math.round(value * 100) / 100).toFixed(2))*100/100
        },
        mixinValidateFields: function(refs)
        {
            let fieldCheck = true,
                fieldError = '',
                formCheck = true

            refs.forEach(ref => {

                if(ref.optional && ref.value == '') {
                    if(ref.ref !== undefined)
                    {
                        ref.ref.$el.classList.remove('invalid')
                    }
                    return
                }

                switch (ref.type) {
                    case 'salabam':
                        fieldCheck = ((ref.value).length < 5 | !(/^[a-z0-9]+$/i.test(ref.value))) ? false : true
                        fieldError = 'salabam non valido'
                    break;

                    case 'pin':
                        var pin = (ref.value).replace(/\s/g,'')
                        fieldCheck = (pin.length !== 16 | !(/^[0-9]+$/i.test(pin))) ? false : true
                        fieldError = 'il pin deve essere composto da 16 cifre'
                    break;

                    case 'redeemCode':
                        var redeem = (ref.value).replace(/\s/g,'')

                        // 3118-455-9b3db38cfcc0d5f4
                        var dashs = (redeem.match(/-/g) || []).length; // conto i "-"

                        fieldCheck = (redeem.length >= 20 && dashs == 2) ? true : false

                        fieldError = 'il formato del codice non è corretto'
                    break;

                    case 'discountCode':
                        fieldCheck = ((ref.value).length !== 16) ? false : true
                        fieldError = 'codice buono non valido'
                    break;

                    case 'address':
                        fieldCheck = ((ref.value).length < 4) ? false : true
                        fieldError = 'questo indirizzo non è valido'
                    break;
                    
                    case 'city':
                        fieldCheck = ((ref.value).length < 3 | !(/^[a-zA-Z 'àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+$/.test(ref.value))) ? false : true
                        fieldError = 'questa città non è valida'
                    break;
                    
                    case 'country':
                        fieldCheck = ((ref.value).length < 3 | !(/^[a-zA-Z 'àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+$/.test(ref.value))) ? false : true
                        fieldError = 'questo paese non è valido'
                    break;
                    
                    case 'postalCode':
                        fieldCheck = ((ref.value).length !== 5) ? false : true
                        fieldError = 'il CAP non è valido'
                    break;
                    
                    case 'province':
                        fieldCheck = ((ref.value).length !== 2) ? false : true
                        fieldError = 'la sigla della provincia deve essere formata da due lettere'
                    break;

                    case 'fiscalCode':
                        fieldCheck = ((ref.value).length !== 16) ? false : true
                        fieldError = 'completa o verifica la correttezza del codice'
                    break;

                    case 'email':
                        fieldCheck = (/\S+@\S+\.\S+/.test(ref.value)) ? true : false
                        fieldError = 'questo indirizzo email non è valido'
                    break;

                    case 'emailConfirm':
                        fieldCheck = ref.value
                        fieldError = 'la mail di conferma è diversa da quella inserita'
                    break;

                    case 'firstName':
                        fieldCheck = ((ref.value).length < 2) ? false : true
                        fieldError = 'il nome deve essere composto da almeno tre caratteri'
                    break;

                    case 'lastName':
                        fieldCheck = ((ref.value).length < 2) ? false : true
                        fieldError = 'il cognome deve essere composto da almeno tre caratteri'
                    break;

                    case 'phone':
                        fieldCheck = ((ref.value).length < 4) ? false : true
                        fieldError = 'numero di telefono non valido'
                    break;

                    case 'vat':
                        fieldCheck = ((ref.value).length < 7) ? false : true
                        fieldError = 'partita iva non valido'
                    break;

                    case 'sdi':
                        fieldCheck = ((ref.value).length < 4) ? false : true
                        fieldError = 'codice destinatario non valido'
                    break;

                    case 'checkbox':
                        fieldCheck = (ref.value == true)
                        fieldError = 'questo campo è obbligatorio'
                    break;

                    case 'textarea':
                        fieldCheck = ((ref.value).length < 4) ? false : true
                        fieldError = 'questo campo non può essere vuoto'
                    break;

                    case 'otp':
                        fieldCheck = ((ref.value).length !== 6) ? false : true
                        fieldError = 'Il campo OTP deve contenere 6 caratteri'
                    break;

                    default:
                        fieldCheck = false
                        fieldError = ''
                    break;
                }


                // orValidate
                // per gestire campi che possono essere opzionali
                // a patto che sia valido un'altro campo (o un'insieme di campi)
                if(!fieldCheck && ref.value == '' && ref.orValidate !== undefined){
                  fieldCheck = this.mixinValidateFields(ref.orValidate)
                }

                // TODO (quando necessario) xorValidate validazione esclusiva


                
                if(ref.ref !== undefined)
                {
                    if(fieldCheck)
                    {
                        ref.ref.$el.classList.remove('invalid')
                        fieldError = ''
                    }
                    else
                    {
                        if(ref.value !== '') ref.ref.$el.classList.add('invalid')
                    }

                    if(ref.ref.$el.nextSibling && ref.ref.$el.nextSibling.classList.contains('formError'))
                    {
                        if(ref.value !== '') ref.ref.$el.nextSibling.innerHTML = fieldError
                    }
                }

                formCheck = formCheck && fieldCheck
            });

            return formCheck
        },
        /*
        mixinIsWelfare()
        {
            if(this.$config.integration) return this.$config.integration.welfare
            return true;
        },
        mixinHasAvailability()
        {
            return (this.$config.integration.availability >= 0)
        },        
        // utilities per flag welfare
        mixinCanUseCc()
        {
            // TBA: implementare successivamente
        },
        */
        mixinUpdateConfig(updateConfig)
        {
            // attenzione! in base alla chiave che ritorna l'API l'ordine degli oggetti può cambiare => "ForYou" dovrebbe sempre tornare come "9999" => "ForYou"

            if(updateConfig){

                let bands = this.$config['bands'],
                    bandsArray = Object.values(bands)

                bandsArray.forEach((band,index)=>{
                    let found = Object.values(band).indexOf(this.$config.guiSettings.customBoxsetBand)
                    if(found > -1)
                    {
                        delete this.$config['bands'][Object.keys(bands)[index]][Object.keys(band)[found]]
                    }
                })
                //if(process.env.VUE_APP_MODE != 'production') window.console.log('mixinUpdateConfig this.$config before')
                //if(process.env.VUE_APP_MODE != 'production') window.console.log(this.$config)
                if(updateConfig.bands){
                    // inietto la nuova banda
                    let b = updateConfig.bands
                    this.$config['bands'][b.focus][b.key] = b.value;
                }
                if(updateConfig.boxsetsConfig){
                    // inietto il cofanetto
                    let bc = updateConfig.boxsetsConfig;

                    if(!this.$config['boxsetsConfig'][bc.focus]){
                      this.$config['boxsetsConfig'][bc.focus] = {}
                    }

                    if(!this.$config['boxsetsConfig'][bc.focus][bc.band]){
                        // la "banda custom" generalmente manca
                        this.$config['boxsetsConfig'][bc.focus][bc.band] = {};
                    }

                    this.$config['boxsetsConfig'][bc.focus][bc.band][bc.nights] = bc.value;
                }
                //if(process.env.VUE_APP_MODE != 'production') window.console.log('mixinUpdateConfig this.$config after')
                //if(process.env.VUE_APP_MODE != 'production') window.console.log(this.$config)
            }
        },
        mixinGetIntegrationInfo(livePrice)
        {
            let upselling = this.$config.guiSettings.upselling,
                hasIntegration = (this.$config.integration !== undefined && this.$config.integration !== {})

            /* inizio valutazione upselling */
            if(hasIntegration)
            {
                upselling.enabled = (upselling.enabled && this.$config.integration.salabam !== undefined)
            }
            else
            {
                upselling.enabled = false
            }
            if(upselling.enabled)
            {
                let nightsOptions = [],
                minDays = upselling.minDays,
                maxDays = upselling.maxDays
    
                for (let n = minDays; n <= maxDays; n++) {
                    let text = n==1 ? '1 notte' : n+' notti'
                    nightsOptions.push({
                        value: n,
                        text: text
                    })                    
                }
                
                upselling.nightsOptions = nightsOptions
            }
            /* fine valutazione upselling */

            if(!hasIntegration)
            {
                return {
                    isGiftCard: false,//qui perché se è false ho integration.salabam undefined e non riesco a valutarlo
                    sso: false,
                    canCheckout: true,
                    integration: false,
                    mode: 'boxset',
                    class: 'default', //blue
                    constrainFocus: '',
                    upselling: upselling,
                    welfare: false,
                }
            }

            let integration = this.$config.integration,
                availability = this.$config.integration.availability

            if(this.$config.integration.salabam == undefined) 
            {
                return {
                    isGiftCard: false,//qui perché se è false ho integration.salabam undefined e non riesco a valutarlo
                    sso: true,
                    canCheckout: true,
                    integration: integration,
                    mode: 'boxset',
                    class: 'default', //blue
                    constrainFocus: '',
                    upselling: upselling,
                    welfare: this.$config.integration.welfare,
                }
            }

            if(this.$config.integration.salabam !== undefined) 
            {
                return {
                    isGiftCard: this.$config.integration.salabam.isGiftCard,//qui perché se è false ho integration.salabam undefined e non riesco a valutarlo
                    sso: false,
                    canCheckout: true,
                    integration: integration,
                    mode: 'booking',
                    class: 'default', //blue
                    constrainFocus: (this.$config.integration.salabam.focus !== undefined && !Store.state.unlockDestinations) ? this.$config.integration.salabam.focus : '',
                    upselling: upselling,
                    welfare: false,
                }
            }                

            if ( this.mixinHasAvailability() && (livePrice > availability) )
            {
                if(integration == 'jointly')
                {
                    return {
                        isGiftCard: false,//qui perché se è false ho integration.salabam undefined e non riesco a valutarlo
                        sso: true,
                        canCheckout: true,
                        integration: integration.integration,
                        mode: 'booking',
                        class: 'gold',
                        constrainFocus: (this.$config.integration.salabam.focus !== undefined && !Store.state.unlockDestinations) ? this.$config.integration.salabam.focus : '',
                        upselling: upselling,
                        welfare: this.$config.integration.welfare,
                    }
                }
                return {
                    isGiftCard: false,//qui perché se è false ho integration.salabam undefined e non riesco a valutarlo
                    sso: true,
                    canCheckout: (this.$config.integration.welfare == false), //caso sso + welfare = false, può andare al checkout anche se non ha disponibilità sufficiente
                    integration: integration.integration,
                    mode: 'booking',
                    class: 'red',
                    constrainFocus: (this.$config.integration.salabam.focus !== undefined && !Store.state.unlockDestinations) ? this.$config.integration.salabam.focus : '',
                    upselling: upselling,
                    welfare: this.$config.integration.welfare,
                }
            }
            
            return {
                isGiftCard: false,//qui perché se è false ho integration.salabam undefined e non riesco a valutarlo
                sso: false,
                canCheckout: true,
                integration: false,
                mode: 'boxset',
                class: 'default', //blue
                constrainFocus: '',
                upselling: upselling,
                welfare: false,
            }
        },
        mixinBoxsetSelected(boxset)
        {
            let bookingData = Store.state.bookingData

            //let bookingData = {

                bookingData.boxset_id = boxset.boxset_id,
                bookingData.public_price = boxset.public_price,
                bookingData.token = this.$config.token,
                bookingData.nights = Store.state.dates.nights,
                //bookingData.nightlyRate = '',
                bookingData.arrivalDate = Store.state.dates.checkin,
                bookingData.departureDate = Store.state.dates.checkout,
                bookingData.numberOfAdults = Store.state.occupancy.adults,
                bookingData.childs = Store.state.occupancy.children,
                bookingData.numberOfChildren = Store.state.occupancy.children.length,
                //guestFirstName: '',
                //guestLastName: '',
                //guestEmail: '',
                //guestEmailConf: '',
                //guestPhone: '',
                //guestPrivacy: '',
                //billToPax
                bookingData.payments = {
                    'welfare': {
                        'amount': 0,
                    },
                    'cc': {
                        'amount': 0,
                        'nameSurname': '',
                        'paymentIntentId': ''
                    },
                    'etc': {
                        'amount': 0,
                        'vouchers': [],
                    },
                    'discountCodes': {
                      'amount': 0,
                      'codes': [],
                    }
                },    
            //}

            this.mixinSendMutation('setBookingData', bookingData)
        },
        mixinCanBillToPax()
        {
            if (!this.mixinGetIntegrationInfo().integration) return false
            return this.$config.integration.billToPax
        },
        mixinGetBoxsetFromConfig(boxset_id)
        {
            // cerca un boxset nella conf in base all'id
            // ritorna anche le chiavi in cui è stato trovato (focus/band/nights)
            for (let [focus, bands] of Object.entries(this.$config.boxsetsConfig)) {
                for (let [band, nights] of Object.entries(bands)) {
                    for (let [night, boxset] of Object.entries(nights)) {
                        if(parseInt(boxset.boxset_id) == boxset_id)
                        {
                            return {
                                boxset: {
                                    ...boxset,
                                    focus: focus,
                                    band: band,
                                    nights: parseInt(night) // !!! la proprietà del boxset si chiama 'nights' (s finale) ma in questi loops faceva comodo chiamarla 'night' (senza s finale)
                                }, // come da conf
                            } // return
                        } // each nights
                    } // each bands
                } // each focus
            } // each boxsets config
            return {}  
        },

        mixinHasDiscount()
        {
            // se $config.integration.discount è undefined, oppure (per x motivi) è pari a 0 o ad 1 allora ritorno un -1 (cioè falsy)
            if(this.$config.integration == undefined) return -1
            if(this.$config.integration.discount == undefined) return -1
            if(this.$config.integration.discount == '') return -1
            if(this.$config.integration.discount == '0') return -1
            if(this.$config.integration.discount == '1') return -1

            return parseFloat(this.$config.integration.discount)
        },

        mixinShowNonRefundableModal()
        {
            // solamente in booking e se la funzionalità è attiva
            let showNonRefundableModal = (false && this.mixinGetIntegrationInfo().mode == 'booking'); // TODO legare a config.guiSettings.showNonRefundableModal (non ancora disponibile)

            if(showNonRefundableModal){

              if(process.env.VUE_APP_MODE != 'production') window.console.log('trackNonRefundableModal: opened');

              if(this.$gtm) this.$gtm.trackEvent({
                event: 'NonRefundableModal',
                category: 'userBehaviour',
                action: 'opened',
                label: '',
                value: 0,
              });

              return true; // fa mostrare la modal
            }

            return false; // ignora la modal, continua con azione di default
        },

        // crea una copia non reattiva di una variabile
        // in js i valori vengono assegnati per riferimento, quindi se si vuole crearne una copia bisogna clonarli
        // questo mixin gestisce sia dati semplici che oggetti
        mixinMakeNonReactiveCopy(original) {

            if (! _.isObject(original) || _.isArray(original)) {

                return original
            }

            let copy = {}
            let attributes = Object.keys(original)
            attributes.forEach(attribute => {
                let attributeValue = Object.getOwnPropertyDescriptor(original, attribute)
                Object.defineProperty(copy, attribute, {
                    __proto__: null,
                    value: attributeValue.get()
                })
            })

            return copy
        },

    },
};