import Api from './api'
import Vue from 'vue'
//import Store from './vuex/store'

export default {
    created(){
        //
    },
    data(){
        return {
            //configData: {}
        }
    },
    methods: {
        appConfig() {
            if(Object.keys(Vue.prototype.$config).length == 0)
            {
              let params = {}

              let urlParams = new URLSearchParams(window.location.search)
              if(urlParams.get('custom') !== undefined)
              {
                params.custom = urlParams.get('custom')
              }

                let promise = Api.getConfig(params)
                promise.then((value) => {
                    Vue.prototype.$config = value
                    if(value.token/* && Store.state.token == ''*/)
                    {
                        this.mixinSendMutation('setToken',value.token)
                    }
                    if(this.mixinGetIntegrationInfo().sso)
                    {
                      // il mixin setAvailability non setta la disponibilità se non è già presente state.integration.availability
                      this.$store.state.integration.availability = -1
                      this.mixinSendMutation('setAvailability',parseFloat(this.mixinGetIntegrationInfo(0).integration.availability))
                    }
                })
                return promise
            }
            return new Promise((resolve, reject) => {
                resolve(Vue.prototype.$config);
                reject('Could not load app config from config.js');
            });
        }
    }
}